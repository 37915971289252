import { BottomNavigation, BottomNavigationAction, Button } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { HomeIcon, UserIcon, StarIcon, ClipboardDocumentListIcon, PlusIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import Cookies from "js-cookie";
import config from "../config";

const navBarStyle: React.CSSProperties = {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "white",
    boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)",
    borderTopLeftRadius: '30px',
    borderTopRightRadius: '30px',
    paddingBottom: '1rem',
    zIndex: 1100,
};

const iconStyle: React.CSSProperties = {
    height: "1.7rem",
    width: "1.7rem",
};

const addNav: React.CSSProperties = {
    color: "white",
    background: '#F9C626',
    width: '2.5rem',
    height: '2.5rem',
    padding: '0.2rem',
    marginTop: '-1rem',
    borderRadius: "5rem",
}

const Navbar = () => {


    const navigate = useNavigate();
    const addWaffle = async () => {

        const apiUrl = config.apiUrl + `waffles/uploadUrl`;
        axios.get(apiUrl, {
            headers: {
                Authorization: `Bearer ${Cookies.get('captainWaffles')}`,
                'Content-Type': 'application/json',

            }
        })
            .then(async res => {
                const parkingImgURL = res.data.data.uploadURL;
                navigate('/enterWaffle', { state: { parkingImgURL } });
            }).catch(err => {
                console.log('err', err);

            })

    };

    return (
        <BottomNavigation sx={navBarStyle}>
            <Link to="/" style={{ textDecoration: "none" }}>
                <BottomNavigationAction
                    label="Home"
                    icon={<HomeIcon style={iconStyle} />}
                />
            </Link>
            <Link to="/profile" style={{ textDecoration: "none" }}>
                <BottomNavigationAction
                    label="Profile"
                    icon={<UserIcon style={iconStyle} />}
                />
            </Link>
            <PlusIcon style={addNav} onClick={addWaffle} />


            <Link to="/scoreboard" style={{ textDecoration: "none" }}>
                <BottomNavigationAction
                    label="Scoreboard"
                    icon={<StarIcon style={iconStyle} />}
                />
            </Link>
            <Link to="/rules" style={{ textDecoration: "none" }}>
                <BottomNavigationAction
                    label="Rules"
                    icon={<ClipboardDocumentListIcon style={iconStyle} />}
                />
            </Link>
        </BottomNavigation>
    );
};

export default Navbar;
