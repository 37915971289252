import React, { useEffect, useState } from 'react';
import { Typography, Box, Button, colors, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import useGet from "../hooks/useGet";

import { styled } from '@mui/material/styles';
import Loader from '../partials/loader';



interface Voters {
    fname: string,
    huserid: number,
    sname: string,
    totalentries: number | null,
    totalpoints: number | null,
}

interface Rank {
    fname: string,
    huserid: number,
    rank: number,
    sname: string,
    totalentries: number | null,
    totalpoints: number | null,
}

const Scoreboard: React.FC = () => {


    const [rankData, setRankData] = useState<Rank[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { data } = useGet('votes');


    //Ranking
    useEffect(() => {
        if (data) {
            let ranked = data.map(function (item: any, i: number) {
                if (i > 0) {
                    //Get our previous list item
                    var prevItem = data[i - 1];
                    if (prevItem.totalpoints === item.totalpoints) {
                        //Same score = same rank
                        item.rank = prevItem.rank;
                    } else {
                        //Not the same score, give em the current iterated index + 1
                        item.rank = i + 1;
                    }
                } else {
                    //First item takes the rank 1 spot
                    item.rank = 1;
                }
                return item;

            });
            setRankData(ranked)
            setIsLoading(false)
        };
    }, [data]);




    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.black,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    // const StyledTableRow = styled(TableRow)(({ theme }) => ({
    //     '&:nth-of-type(odd)': {
    //         backgroundColor: theme.palette.action.hover,
    //     },
    //     // hide last border
    //     '&:last-child td, &:last-child th': {
    //         border: 0,
    //     },
    // }));



    return (
        <Box
            sx={{
                height: '100%',
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '1rem'
            }}>
            <Typography variant="h5" sx={{ pt: 3, mb: 2 }}>Scoreboard</Typography>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">#</StyledTableCell>
                            <StyledTableCell align="left">Waffler</StyledTableCell>
                            <StyledTableCell align="center">Total Entries</StyledTableCell>
                            <StyledTableCell align="center">Approved</StyledTableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rankData && rankData.map((row: any) => (
                            <TableRow key={row.huserid}>
                                <StyledTableCell component="th" scope="row" align="center">
                                    {row.rank}
                                </StyledTableCell>
                                <StyledTableCell align="left">{row.fname + ' ' + row.sname}</StyledTableCell>
                                <StyledTableCell align="center">{row.totalentries}</StyledTableCell>
                                <StyledTableCell align="center" sx={{ color: 'secondary.main', fontWeight: '600' }}>{row.totalpoints}</StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


            {isLoading && <Loader loading={isLoading}></Loader>}

        </Box>

    )
}

export default Scoreboard;