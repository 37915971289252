import { Typography, Box } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import config from "../config";
import axios from 'axios';
import { ArrowBackRounded, CancelOutlined, CheckCircleOutline, ThumbDown, ThumbDownOutlined, ThumbUp, ThumbUpOutlined } from "@mui/icons-material";
import ErrorModal from "../partials/ErrorModal";
import Loader from "../partials/loader";

interface WaffleData {
    activeyn: string,
    datecreated: string,
    fname: string,
    huserid: number,
    hwaffleid: number,
    imagereference: string | null,
    sname: string,
    uservoteyn: number | null,
    votesno: number | null,
    votesyes: number | null,
    waffledescription: string | null,
    waffleimageurl: string,
    wafflename: string,
    wafflestatus: string,
}

interface Params {
    HwaffleID: number | undefined;
    voteYesYN: number;
    voteNoYN: number;
    voterComments: string;
}

const ViewWaffle = () => {

    const { id } = useParams<{ id: string }>()


    const [waffleData, setwaffleData] = useState<WaffleData | undefined>();
    const [currVote, setCurrVote] = useState<string | null>(null);
    const [qualified, setQualified] = useState<boolean>(true);
    const [yesVotes, setYesVotes] = useState<number>(0);
    const [noVotes, setNoVotes] = useState<number>(0);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);


    let apiUrl1: string;
    apiUrl1 = config.apiUrl + `waffles/${id}`;

    useEffect(() => {
        axios.get(apiUrl1, {
            headers: {
                Authorization: `Bearer ${Cookies.get('captainWaffles')}`
            }
        })
            .then(res => {
                const waffle = res.data.data[0];
                setwaffleData(waffle)
                waffle.votesno ? setNoVotes(waffle.votesno) : setNoVotes(0);
                waffle.votesyes ? setYesVotes(waffle.votesyes) : setYesVotes(0);
                waffle.uservoteyn === 1 && setCurrVote('Yes');
                waffle.uservoteyn === 0 && setCurrVote('No');
                waffle.wafflestatus === 'Rejected' ? setQualified(false) : setQualified(true);
                setIsLoading(false);
            })
            .catch(error => {
                console.error(error);
            });
    }, [apiUrl1]);


    const formatDate = (val: string) => {

        const January = "January";
        const February = "February";
        const March = "March";
        const April = "April";
        const May = "May";
        const June = "June";
        const July = "July";
        const August = "August";
        const September = "September";
        const October = "October";
        const November = "November";
        const December = "December";

        var monthNames = [
            January,
            February,
            March,
            April,
            May,
            June,
            July,
            August,
            September,
            October,
            November,
            December,
        ];

        var date = new Date(val);
        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();
        return day + " " + monthNames[monthIndex] + " " + year;
    };

    const handleVoteClick = (vote: number, event: React.MouseEvent<SVGElement, MouseEvent>) => {
        event.stopPropagation();
        setIsLoading(true);
        const params: Params = {
            HwaffleID: waffleData?.hwaffleid,
            voteYesYN: vote === 1 ? 1 : 0,
            voteNoYN: vote === 1 ? 0 : 1,
            voterComments: '',
        };


        const apiUrl = config.apiUrl + 'votes';
        axios
            .post(apiUrl, params, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('captainWaffles')}`,
                },
            })
            .then(res => {
                vote === 1 ? setCurrVote('Yes') : setCurrVote('No');
                const voteData = res.data.data;
                voteData.waffleStatus === 'Rejected' ? setQualified(false) : setQualified(true);
                setNoVotes(voteData.voteNoYN || 0);
                setYesVotes(voteData.voteYesYN || 0);
                setIsLoading(false);

            })
            .catch(error => {
                setError(`You can't vote on your own waffle. Dickhead.`);
                setIsLoading(false);
            });
    };

    const closeModal = () => {
        setError(null);
    };

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };


    // ========== Styling
    const flex: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        marginTop: '1.2rem',
    };


    const imageContainer: React.CSSProperties = {
        // borderRadius: "30px 30px 0 0",
        width: '100%',
        height: '16rem',
        objectFit: 'cover',
        overflow: 'hidden',

    };

    const headerBar: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: '1rem',
        paddingBottom: '0.5rem',
        backgroundColor: 'transparent',
        position: 'static'

    };

    return (
        <Box>
            <Box style={headerBar}>
                <ArrowBackRounded onClick={goBack}></ArrowBackRounded>
            </Box>

            {/* <Typography variant="h5" sx={{ pt: 3, mb: 2 }}>
                View Waffle Details
            </Typography> */}


            {waffleData && <img src={waffleData.waffleimageurl} style={imageContainer} alt="waffle" />}

            <Box sx={{ mt: 1, display: "flex", justifyContent: 'space-between' }}>
                <Typography variant='body2' >{waffleData?.fname} {waffleData?.sname}</Typography>
                <Typography variant='body2' >{waffleData && formatDate(waffleData.datecreated)}</Typography>
            </Box>

            <Box sx={{ mt: 2 }}>
                <Typography variant='h6'>{waffleData?.wafflename}</Typography>
                <Typography variant="body2">{waffleData?.waffledescription}</Typography>
            </Box>

            <Box style={flex} >
                {currVote === 'Yes' ? (
                    <ThumbUp onClick={(event) => handleVoteClick(1, event as React.MouseEvent<SVGElement, MouseEvent>)} sx={{
                        color: 'primary.dark',
                        mr: 1,
                    }} />
                ) : (
                    <ThumbUpOutlined onClick={(event) => handleVoteClick(1, event as React.MouseEvent<SVGElement, MouseEvent>)} sx={{
                        color: 'primary.dark',
                        mr: 1,
                    }} />
                )}
                <Typography variant='subtitle2' sx={{
                    color: 'primary.dark',
                    mr: 1,
                }}>{yesVotes}</Typography>
                {currVote === 'No' ? (
                    <ThumbDown onClick={(event) => handleVoteClick(0, event as React.MouseEvent<SVGElement, MouseEvent>)} sx={{
                        color: 'secondary.main',
                        mr: 1,
                    }} />
                ) : (
                    <ThumbDownOutlined onClick={(event) => handleVoteClick(0, event as React.MouseEvent<SVGElement, MouseEvent>)} sx={{
                        color: 'secondary.main',
                        mr: 1,
                    }} />
                )}
                <Typography variant='subtitle2' sx={{
                    color: 'secondary.main',
                    mr: 2,
                }}>{noVotes}</Typography>

            </Box>
            <Box style={flex}> {qualified ? <><CheckCircleOutline sx={{
                color: 'success.light'
            }} /> <Typography variant="subtitle2" sx={{ ml: 1 }}>Accepted</Typography></>
                : <> <CancelOutlined sx={{
                    color: 'error.main'
                }} /><Typography variant="subtitle2" sx={{ ml: 1 }}>Rejected</Typography></>}
            </Box>


            {error && <ErrorModal error={error} onClose={closeModal} />}

            {isLoading && <Loader loading={isLoading}></Loader>}
        </Box>
    )
}

export default ViewWaffle