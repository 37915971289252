import { Typography, TextField, Box, IconButton, Tabs, Tab, Button, InputAdornment } from "@mui/material"; import { useContext, useState } from "react";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../context/userContext";
import Cookies from 'js-cookie';
import config from "../config";
import Loader from "../partials/loader";
import swDev from '.././swDev.js'




interface User {
  huserid: number;
  token: string;
}


const Login = () => {


  // const [user, setUser] = useState<User | null>(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate()


  console.log('config', config);


  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();
    setIsLoading(true);

    axios.post(config.apiUrl + 'auth/userPass', {
      userpass: password,
      emailaddress: username,
    })
      .then((res) => {
        const userData = res.data.data;

        const inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);
        const in30Minutes = 1 / 48;
        const inHalfADay = 0.5;
        Cookies.set('captainWaffles', `${userData.token}`, { expires: inHalfADay });
        Cookies.set('superWaffles', `${userData.huserid}`, { expires: inHalfADay });
        setTimeout(() => {
          navigate(`/`);
        }, 500);

        // TF =============================================
        // Check if the page has already loaded
        const setSW = () => {
          setTimeout(() => {
            if (document.readyState === 'complete') {
              swDev();
            } else {
              setSW();
            }
          }, 1000);
        };
        setSW();
        // TF =============================================
      })
      .catch((error) => {
        console.log(error);
        setError("Invalid username or password");
      });
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        left: '0',
        height: '100vh',
        width: '100vw',
        maxWidth: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: 'white',
        justifyContent: 'center',
        padding: '2rem',
        paddingTop: '0',
      }}
    >


      <form onSubmit={handleLogin}>

        <Typography variant="h5" sx={{ mb: 3 }}>Login</Typography>

        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          value={username}
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={(e) => setUsername(e.target.value)}
        />

        <TextField
          type={showPassword ? 'text' : 'password'}
          label="Password"
          name="password"
          value={password}
          id="password"
          onChange={handlePasswordChange}
          fullWidth
          required
          autoComplete="current-password"
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleTogglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />


        <Button
          type="submit"
          fullWidth
          variant="contained"
          size="large"
          sx={{ mt: 3, mb: 2 }}
        >
          Login
        </Button>
      </form>

      {error && <p>{error}</p>}
      {isLoading && <Loader loading={isLoading}></Loader>}

    </Box>
  );
};

export default Login;























// import React from 'react';
// import { Typography, TextField, Box, Tabs, Tab, Button } from "@mui/material";
// import { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { UserContext } from '../context/userContext';


// const Login = () => {

//   const [error, setError] = useState<string>('');
//   const [data, setData] = useState<any>(null);
//   const { user, setUser } = useContext(UserContext)
//   const navigate = useNavigate()


//   const submitLogin = (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault();


//     const data = new FormData(event.currentTarget);
//     const loginParams = {
//       userpass: data.get('password'),
//       emailaddress: data.get('email'),
//     };
//     const url = 'https://easy-neckerchief-fish.cyclic.app/api/v1/auth/userPass';
//     // const url = 'http://localhost:3111/api/v1/auth/userPass';

//     const requestOptions: RequestInit = {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       credentials: "include",
//       body: JSON.stringify(loginParams),
//     };

//     fetch(url, requestOptions)
//       .then((response) => response.json())
//       .then((data) => {

//         setUser(data.data)
//         setTimeout(() => {
//           navigate('/');
//         }, 500);
//         console.log('data', data);

//       })
//       .catch((error) => {
//         console.error('Error:', error);
//       });



//   }





//   //Page
//   return (
//     <Box
//       sx={{
//         height: '100%',
//         maxWidth: '100%',
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//       }}
//     >
//       {/* value={value} onChange={handleChange} */}
//       {/* <Tabs  centered>
//           <Tab label="Login" />   
//         </Tabs> */}

//       <Typography variant='h5' >Login</Typography>


//       <form onSubmit={submitLogin}>

//         <TextField
//           margin="normal"
//           required
//           fullWidth
//           id="email"
//           label="Email Address"
//           name="email"
//           autoComplete="email"
//           autoFocus
//         />
//         <TextField
//           margin="normal"
//           required
//           fullWidth
//           name="password"
//           label="Password"
//           type="password"
//           id="password"
//           autoComplete="current-password"
//         />

//         <Button
//           type="submit"
//           fullWidth
//           variant="contained"
//           sx={{ mt: 3, mb: 2 }}
//         >
//           Login
//         </Button>
//       </form>
//     </Box>
//   )
// }

// export default Login