import config from "./config";
import axios from "axios";

export default async function swDev() {
  const vapidKey2 = config.VAPIDKey;

  const urlBase64ToUint8Array = async (base64String) => {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };

  const baseVipID = await urlBase64ToUint8Array(vapidKey2);

  let swUrl = `${process.env.PUBLIC_URL}/sw.js`;
  const subKey = await navigator.serviceWorker.register(swUrl).then((res) => {
    return res.pushManager.getSubscription().then(() => {
      return res.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: baseVipID,
      });
    });
  });

  const subscribeDets = () => {
    axios.post(config.apiUrl + "subscribe", subKey)
    .catch((err) => {
      console.log(err);
    });
  }

  if(Notification.permission === "granted") {
    subscribeDets();
  } else {
    Notification.requestPermission()
    .then(permission => {
      if (permission === "granted") {
        subscribeDets();
      }
    })
  };
}
