import { useEffect, useRef, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import WaffleCard from "../components/waffleCard";
import { Typography, Modal, Drawer, SwipeableDrawer, List, ListItem, ListItemIcon, ListItemButton, Select, MenuItem, OutlinedInput, SelectChangeEvent, InputLabel, FormControl } from "@mui/material";
import ErrorModal from "../partials/ErrorModal";
import Cookies from "js-cookie";

import axios from "axios";
import config from "../config";
import { ArrowBackRounded, TuneRounded } from "@mui/icons-material";
import Loader from "../partials/loader";


interface Waffle {
    activeyn: string;
    datecreated: string;
    fname: string;
    huserid: number;
    hwaffleid: number;
    imagereference: string | null;
    sname: string;
    uservoteyn: number | null;
    votesno: number | null;
    votesyes: number | null;
    waffledescription: string | null;
    wafflename: string;
    wafflestatus: string;
}


interface User {
    fname: string | null;
    huserid: number | null;
    sname: string | null;
}



const Timeline = () => {
    const [waffler, setWaffler] = useState<string>('All');
    const [waffStatus, setWaffleStatus] = useState<string>('Any');
    const [visibleWaffles, setVisibleWaffles] = useState<Waffle[]>([]);
    const [visibleCount, setVisibleCount] = useState<number>(10);
    const containerRef = useRef<HTMLDivElement>(null);
    const [data, setData] = useState<Waffle[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
    const [users, setUsers] = useState<User[]>([]);
    const [drwState, setDrwState] = useState<boolean>(false);
    const [isfiltering, setIsFiltering] = useState<boolean>(false);

    useEffect(() => {
        const handleScroll = () => {
            if (
                containerRef.current &&
                containerRef.current.scrollTop + containerRef.current.clientHeight >=
                containerRef.current.scrollHeight
            ) {
                console.log("Loading new items...");
                setVisibleCount((prevCount) => prevCount + 10);
            }
        };

        if (containerRef.current) {
            containerRef.current.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        if (error) {
            setIsErrorModalOpen(true);
        }
    }, [error]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(config.apiUrl + 'users', {
                    headers: {
                        Authorization: `Bearer ${Cookies.get('captainWaffles')}`
                    },
                });
                setUsers(response.data.data);
            } catch (error) {
                console.log('error', error);
            }
        };

        fetchUsers();
    }, []);

    const waffles = useMemo(() => data, [data]);

    useEffect(() => {
        setVisibleWaffles(waffles.slice(0, visibleCount));
    }, [waffles, visibleCount]);

    const handleModalClose = () => {
        setIsErrorModalOpen(false);
    };

    const wafflerChange = (event: SelectChangeEvent) => {
        setWaffler(event.target.value);
    };

    const statusChange = (event: SelectChangeEvent) => {
        setWaffleStatus(event.target.value);
    };

    useEffect(() => {
        const callWaffles = async () => {
            setIsFiltering(true)
            const filterOpts: { [key: string]: any } = {};
            const wafflerID = waffler !== "All" ? parseInt(waffler) : null;
            if (wafflerID && wafflerID !== null) {
                filterOpts.HuserID = wafflerID

            }
            const waffStati = waffStatus !== "Any" ? waffStatus : null;
            if (waffStati && waffStati !== null) {
                filterOpts.waffleStatus = `'${waffStati}'`

            };


            try {
                const response = await axios.post(config.apiUrl + 'waffles/filter', { filterOpts: filterOpts }, {
                    headers: {
                        Authorization: `Bearer ${Cookies.get('captainWaffles')}`
                    },
                }

                );
                setData(response.data.data);
                setIsFiltering(false)
            } catch (error) {
                setIsFiltering(false)
                setError('Oops. Waffles are still cooking! Please try again later.');
            }
        };

        callWaffles();
    }, [waffler, waffStatus]);

    const toggleDrawer = (open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setDrwState(open);
        };

    const goBack = () => {
        setDrwState(false);
    };

    const list = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
        >
            <FormControl fullWidth>
                <List sx={{ marginTop: 1 }}>

                    <ListItem disablePadding sx={{ marginBottom: 2 }}>
                        <ListItemButton >
                            <InputLabel id="wafflerLabel" sx={{ marginLeft: 2, marginTop: 1 }}>Waffler</InputLabel>
                            <Select
                                labelId="wafflerLabel"
                                id="wafflerDD"
                                value={waffler}
                                label="Waffler"
                                onChange={wafflerChange}
                                fullWidth
                            >
                                <MenuItem value={'All'}>All</MenuItem>
                                {users.length > 0 && users.map((user: any) => (
                                    <MenuItem value={user.huserid} key={user.huserid}>
                                        {user.fname}
                                    </MenuItem>
                                ))}
                            </Select>
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton>
                            <InputLabel id="statusLabel" sx={{ marginLeft: 2, marginTop: 1 }}>Status</InputLabel>
                            <Select
                                labelId="statusLabel"
                                id="statusDD"
                                value={waffStatus}
                                label="Status"
                                onChange={statusChange}
                                fullWidth
                            >
                                <MenuItem value={'Any'}>Any</MenuItem>
                                <MenuItem value={'Accepted'}>Accepted</MenuItem>
                                <MenuItem value={'Rejected'}>Rejected</MenuItem>
                            </Select>
                        </ListItemButton>
                    </ListItem>
                </List>
            </FormControl>

        </Box>
    );

    const isLoading = error || !data || (data && data.length === 0);

    const filterBar: React.CSSProperties = {
        position: 'fixed',
        right: '10px',
        top: '10px',
        backgroundColor: 'white',
        borderRadius: '30px',
        zIndex: '100',
        padding: '10px 1px 2px 10px',
    };

    return (
        <Box>

            <Box sx={{ ...filterBar, boxShadow: 2 }}>
                <TuneRounded sx={{ marginRight: 1, cursor: 'pointer' }} onClick={toggleDrawer(true)} />
            </Box>

            <SwipeableDrawer
                anchor={'right'}
                open={drwState}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <div
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <List>
                        <ListItem button onClick={goBack}>
                            <ListItemIcon>
                                <ArrowBackRounded />
                            </ListItemIcon>
                        </ListItem>
                    </List>
                    {list()}
                </div>
            </SwipeableDrawer>
            <Box ref={containerRef} sx={{
                paddingTop: 5,
            }}>
                {isLoading ? (
                    isLoading && error ? (
                        <Typography>No waffles yet, time to get eating.</Typography>
                    ) : (
                        <>
                            <Skeleton animation="wave" height={150} />
                            <Skeleton animation="wave" height={150} />
                            <Skeleton animation="wave" height={150} />
                            {/* Add more skeleton items as needed */}
                        </>
                    )
                ) : waffles.length === 0 ? (
                    <Typography>No waffles yet, time to get eating.</Typography>
                ) : (

                    visibleWaffles.map((waffle: Waffle) => (
                        <WaffleCard key={waffle.hwaffleid} waffle={waffle} />
                    )))}
                {waffles.length > visibleCount && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                        <Skeleton variant="rectangular" width={200} height={40} />
                    </Box>
                )}
            </Box>
            {error && <Modal open={isErrorModalOpen} onClose={handleModalClose}>
                <ErrorModal error={error} onClose={handleModalClose} />
            </Modal>}
            {isLoading && <Loader loading={isfiltering}></Loader>}

        </Box>
    );
};

export default Timeline;
