import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const Theme = createTheme({
 palette: {
    primary: {
      main: '#faca2a',
      light: '#ffd742',
      dark: '#eaa901',
    },
    secondary: {
      main: '#1E79AE',
      dark: '#1b4996',
      light: '#39a4df',
    },
  },
  typography: {
    button: {
      lineHeight: 1.55,
      letterSpacing: '0.06em',
      fontWeight: 400,
      textTransform: 'none',
      fontSize: '1rem',
    },
    
  },
   shape: {
    borderRadius: 10,
  },
   

  
});

export default Theme;